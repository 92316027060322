// General imports

// Material-ui imports
import { 
    makeStyles
} from "@material-ui/styles"

// Own imports

// Styles
const useAppStyles = makeStyles({
    app: {
        
    }
})

// Exports
export default useAppStyles